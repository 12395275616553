import React, { useState, useEffect, useMemo } from "react";
import {
  Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TextField, MenuItem, Select, InputLabel, FormControl, Button, ThemeProvider, ImageList, ImageListItem
} from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { mdiMicrosoftExcel } from "@mdi/js";
import Icon from "@mdi/react";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import { debounce } from "lodash";
import theme from "../component/theme";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
 

// Define table columns
const columns = [
  { id: "student_id", label: "Student ID", width: 100 },
  { id: "student_name", label: "Student Name", width: 150 },
  { id: "father_name", label: "Father Name", width: 150 },
  { id: "date_of_birth", label: "DOB", width: 150 },
  { id: "contact_no", label: "Contact No.", width: 150 },
  { id: "batch_name", label: "Batch", width: 150 },
  { id: "aadhaar_no", label: "Aadhaar No.", width: 150 },
  { id: "fingar_print1", label: "Finger 1", width: 200 },
  { id: "fingar_print2", label: "Finger 2", width: 200 },
  { id: "fingar_print3", label: "Finger 3", width: 200 },
  { id: "fingar_print4", label: "Finger 4", width: 200 },
  { id: "fingar_print5", label: "Finger 5", width: 200 },
];

export default function StudentList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState("");
  const [batchNames, setBatchNames] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const navigate = useNavigate();


  // Fetch data from API on mount
  useEffect(() => {

    const token = Cookies.get("login_jwtToken");
    
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/studentlist`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
             'authorization': `Bearer ${token}`,
          },
        });
        
        const data = await response.json();
       
        if (data.success && Array.isArray(data.success)) {
          setRows(data.success);
          console.log(data.success)
          const batches = [...new Set(data.success.map((student) => student.batch_name))];
          
          setBatchNames(batches);
        } else {
          toast.error(data.error);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };
    fetchData();
  }, []);

 
  const handleBatchChange = (event) => {
    setSelectedBatch(event.target.value);
    
    setPage(0); 
  };

  // Export to CSV
  const handleExport = () => {
    const csvContent = [
      columns.map((col) => col.label).join(","),
      ...filteredRows.map((row, index) =>
        [page * rowsPerPage + index + 1, ...columns.slice(1).map((col) => row[col.id] || "")].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `students_list_${Date.now()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Handle search input with debounce
  const debouncedSearch = debounce((value) => setSearchText(value), 300);
  const handleSearchChange = (e) => {
    debouncedSearch(e.target.value);
  };

  // Filter rows based on search and selected batch
  const filteredRows = useMemo(() => {
    return rows
      .filter((row) => selectedBatch ? row.batch_name === selectedBatch : true)
      .filter((row) => {
        return (
          row.student_name?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.father_name?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.date_of_birth?.toLowerCase().includes(searchText.toLowerCase()) ||
          row.contact_no?.toString().includes(searchText) ||
          row.aadhaar_no?.toString().includes(searchText)
        );
      });
  }, [rows, searchText, selectedBatch]);

  // Handle sorting
  const handleSort = (columnId) => {
    const isAsc = sortedColumn === columnId && sortDirection === "asc";
    setSortDirection(isAsc ? "desc" : "asc");
    setSortedColumn(columnId);

    const sortedRows = [...rows].sort((a, b) => {
      const aValue = a[columnId] || "";
      const bValue = b[columnId] || "";
      return (aValue > bValue ? 1 : -1) * (isAsc ? 1 : -1);
    });

    setRows(sortedRows);
  };

  // Handle pagination
  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
 


  // Edit action
  const handleEdit = (row) => {

    let fingerprints = {
      fingar_print1: row.fingar_print1,
      fingar_print2: row.fingar_print2,
      fingar_print3: row.fingar_print3,
      fingar_print4: row.fingar_print4,
      fingar_print5: row.fingar_print5
    };
  
    const fingerprintsString = encodeURIComponent(JSON.stringify(fingerprints));


    toast.info(`Editing row for Student ID: ${row.student_id}`);
    navigate(`/Student/${row.student_id}/${row.student_name}/${row.father_name}/
      ${row.date_of_birth}/${row.contact_no}/${row.batch_id}/${row.aadhaar_no}/
      ${fingerprintsString.fingar_print1}`);
  };

  // Render image using ImageList and ImageListItem
  const renderImage = (imagePath, index) => {
  if (imagePath) {
    // If imagePath is a string, split it into an array; otherwise, assume it's already an array
    const imagePaths = typeof imagePath === 'string' ? imagePath.split(',') : [imagePath];

    return (
      <ImageList sx={{ width: 80, height: 80, gap: 1 }}>
        {imagePaths.map((path, imgIndex) => (
          <ImageListItem key={`${index}-${imgIndex}`}>
            <img
              src={`${path}`} // Use URL from your server
              alt={`Fingerprint ${imgIndex + 1}`}
              loading="lazy"
              style={{ maxWidth: 70, height: 70}}
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  }
  return null;
};


  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", padding: 1 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" gap={2} alignItems="center">
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel>Batch Name</InputLabel>
              <Select value={selectedBatch} onChange={handleBatchChange} label="Batch Name">
                <MenuItem value="">All Batches</MenuItem>
                {batchNames.map((batch) => (
                  <MenuItem key={batch} value={batch}>{batch}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button onClick={handleExport} startIcon={<Icon path={mdiMicrosoftExcel} size={1} color="green" />}>
              <FileDownload />
            </Button>
          </Box>
          <TextField size="small" label="Search" variant="outlined" onChange={handleSearchChange} sx={{ width: "20%" }} />
        </Box>
        
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell sx={{fontSize:17, fontWeight: 'bold', width: '15%' }}
                    key={column.id}
                    style={{ minWidth: column.width, cursor: "pointer" }}
                    onClick={() => handleSort(column.id)}
                  >
                    {column.label} {sortedColumn === column.id ? (sortDirection === "asc" ? " ▲" : " ▼") : ""}
                  </TableCell>
                ))}
                <TableCell style={{ minWidth: 100 }}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                <TableRow key={index} style={{ height: '60px' }}>
                  {columns.map((column, colIndex) => (
                    <TableCell key={column.id || colIndex} style={{ fontSize: '16px' }} >
                      {column.id.includes("fingar_print") ? renderImage(row[column.id]) : row[column.id] || ""}
                    </TableCell>
                  ))}
                  <TableCell>
                    <Button onClick={() => handleEdit(row)}>
                      <EditIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </ThemeProvider>
  );
}