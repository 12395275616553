import React, { useState, useEffect, useMemo } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  CircularProgress,
  Modal,
} from "@mui/material";
import { toast } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../component/theme";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import Cookies from "js-cookie";
import {jwtDecode }  from "jwt-decode"; 

const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function View_Paymentdetails() {
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({
    key: "Batch_No",
    direction: "ascending",
  });
  const [modalOpen, setModalOpen] = useState(false); // State for modal
  const [imageSrc, setImageSrc] = useState(''); // State for image source
  const navigate = useNavigate();

  console.log(imageSrc)

  // Fetching data from the API
  useEffect(() => {

         
    const token = Cookies.get("login_jwtToken");
    if (token) {
        try {
            
            const decodedToken = jwtDecode(token);
            console.log("Decoded Token:", decodedToken); 
            
           
        } catch (error) {
            console.error("Error decoding token:", error);
        }
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/viewpayment`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
             'authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) throw new Error("Network response was not ok");

        const data = await response.json();
        if (data.success && Array.isArray(data.success)) {
          setRows(data.success);
          setAllRows(data.success);
        } else if (data.success === "No Data found") {
          toast.error("No Data found");
        } else {
          toast.error("Unexpected API response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        const filteredRows = allRows.filter((row) => {
          return (
            (row.Batch_No &&
              row.Batch_No.toString()
                .toLowerCase()
                .includes(value.toLowerCase())) ||
            (row.Reference_No &&
              row.Reference_No.toLowerCase().includes(value.toLowerCase())) ||
            (row.State && row.State.toLowerCase().includes(value.toLowerCase()))
          );
        });
        setRows(filteredRows);
        setPage(0);
      }, 300),
    [allRows]
  );

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedRows = useMemo(() => {
    let sortableItems = [...rows];
    sortableItems.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    return sortableItems;
  }, [rows, sortConfig]);

  const displayedRows = sortedRows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  // Function to open modal
  const handleOpenModal = (src) => {
    setImageSrc(src);
    setModalOpen(true);
  };

  // Function to close modal
  const handleCloseModal = () => {
    setModalOpen(false);
    setImageSrc('');
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <Main>
          <Typography sx={{ fontWeight: "bold", color: "white", mb: -5 }}>
            Manage Your Payments
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
            <Button type="submit" variant="contained" color="primary" onClick={() => navigate("/Payment_details")}>
              +CREATE NEW
            </Button>
          </Box>

          <Box sx={{ border: 2, width: "auto", borderRadius: 4 }}>
            <Box sx={{ align: "right", display: "flex", justifyContent: 'flex-end', margin: 2 }}>
              <TextField
                size="small"
                label="Search Payment"
                variant="outlined"
                value={searchText}
                onChange={handleSearch}
                sx={{ justifyContent: "flex-end" }}
              />
            </Box>

            {loading ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : (
              <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          width: "25%",
                          fontSize: 20,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => requestSort("Batch_No")}
                      >
                        Batch No.{" "}
                        {sortConfig.key === "Batch_No"
                          ? sortConfig.direction === "ascending"
                            ? "▲"
                            : "▼"
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "25%",
                          fontSize: 20,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => requestSort("Reference_No")}
                      >
                        Reference No{" "}
                        {sortConfig.key === "Reference_No"
                          ? sortConfig.direction === "ascending"
                            ? "▲"
                            : "▼"
                          : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "25%",
                          fontSize: 20,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => requestSort("State")}
                      >
                        State{" "}
                        {sortConfig.key === "State" ? sortConfig.direction === "ascending" ? "▲" : "▼" : ""}
                      </TableCell>
                      <TableCell
                        style={{
                          width: "25%",
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        Payment Screenshot
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedRows.map((row, index) => (
                      <TableRow key={`${row.Batch_No}-${index}`}>
                        <TableCell>{row.Batch_No}</TableCell>
                        <TableCell>{row.Reference_No}</TableCell>
                        <TableCell>{row.State}</TableCell>
                        <TableCell>
                          {row.Payment_File ? (
                            <img
                             // src={row.Payment_File}
                               src={`${process.env.FILE_URL}/${row.Payment_File}`}
                              alt="View Screenshot"
                               onClick={() => handleOpenModal(`${process.env.FILE_URL}/${row.Payment_File}`)} // Open modal on click
                             style={{ width: "100px", cursor: "pointer" }}
                            />
                          ) : (
                            "No Image"
                           )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>

          {/* Modal for viewing payment screenshot */}
          <Modal open={modalOpen} onClose={handleCloseModal}>
            <Box sx={modalStyle}>
              <img src={imageSrc} alt="Payment Screenshot" style={{ width: '100%', height: 'auto' }} />
            </Box>
          </Modal>
        </Main>
      </Box>
    </ThemeProvider>
  );
}
