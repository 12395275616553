// theme.js
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark', // dark mode for better contrast
    background: {
      default: '#000000', // black background
      paper: '#121212', // lighter black for elements like paper, AppBar, Drawer
    },
    text: {
      primary: '#ffffff', // white text color for readability
      secondary: '#aaaaaa', // grey text for secondary items
    },
    primary: {
      main: '#90caf9', // light blue primary color
    },
    secondary: {
      main: '#f48fb1', // pink secondary color
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#121212', // AppBar background color
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#121212', // Drawer background color
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8, // Rounded corners
          padding: '8px 16px', // Padding
        },
        contained: {
          backgroundColor: '#90caf9', // Background color for contained buttons
          '&:hover': {
            backgroundColor: '#81d4fa', // Hover color
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#ffffff', // Label color
          '&.Mui-focused': {
            color: '#90caf9', // Focused label color
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#1f1f1f', // Background color for TextFields
          borderRadius: 4, // Rounded corners
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: '#1f1f1f', // Background color for Select
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#333333', // Hover background color for MenuItems
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'black', // Black background for TableCell
          color: '#ffffff', // White text for better contrast
        },
        head: {
          backgroundColor: '#1f1f1f', // Lighter black for table header
          color: '#ffffff', // White text for header
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          backgroundColor: 'black', // Black background for TableCell
          color: '#ffffff', // White text for better contrast
        },
      },
    },
    // Add more components as needed
  },
});

export default darkTheme;
