import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
} from "@mui/material";

const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  
  }),
}));

export default function PermissionManagement() {
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [checkedItems, setCheckedItems] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [RoleItems, setRoleItems] = useState([]);
  const [UserItems, setUserItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/useraccess`);
        const data = await response.json();

        if (data.success && Array.isArray(data.success)) {
          setMenuItems(data.success);
          setRoleItems(data.rolelist);
          setUserItems(data.userlist);
        } else {
          toast.error("Unexpected API response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };
    fetchData();
  }, []);

  // Fetch permission for users
  const fetchpermission = async (selectedUser, selectedRole) => {
    try {
      const queryParams = new URLSearchParams();
      if (selectedRole) {
        queryParams.append("roleId", selectedRole);
      }
      if (selectedUser) {
        queryParams.append("userId", selectedUser);
      }

      const response1 = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/useraccess/getpermission?${queryParams.toString()}`,
        {
          method: "GET",
        }
      );
      const data1 = await response1.json();

      if (data1.success && Array.isArray(data1.success)) {
        // Create a new object for checkedItems based on the returned menu IDs
        const newCheckedItems = {};
        
        data1.success.forEach(item => {
          // Assuming each item contains 'menu_id' and you want to check it
          newCheckedItems[item.menu_id] = true; // Set the checkbox as checked for this menu_id
        });
        
        setCheckedItems(newCheckedItems); // Update the checkedItems state

        console.log(data1.success);
      } else {
        toast.error("No permissions found");
        setCheckedItems({}); // Clear previous checkboxes if no permissions found
      } 
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Error fetching data");
    }
  };

  // Handle Role Selection
  const handleRoleChange = (event) => {
    const newSelectedRole = event.target.value;
    setSelectedRole(newSelectedRole);
    fetchpermission(selectedUser, newSelectedRole); // Use current selectedUser from state
  };

  // Handle User Selection
  const handleUserChange = (event) => {
    const newSelectedUser = event.target.value;
    setSelectedUser(newSelectedUser);
    fetchpermission(newSelectedUser, selectedRole); // Use current selectedRole from state
  };

  // Handle Checkbox Change for Menu Permissions
  const handleCheckboxChange = (menuItem) => {
    const { id } = menuItem; // Destructure the id from the menuItem
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [id]: !prevCheckedItems[id], // Toggle the value based on previous state
    }));
  };

  // Handle Update Button
  const handleUpdate = async () => {
    if (!selectedRole && !selectedUser) {
      toast.error("Please Select either a role or a user");
      return;
    }

    const permissions = menuItems
      .filter((menuItem) => checkedItems[menuItem.id]) // Only keep checked items
      .map((menuItem) => ({
        id: menuItem.id, // Return only the 'id'
      }));

    // Call Role-wise API if a role is selected
    if (selectedRole) {
      const roleResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/useraccess/rolewise`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ roleid: selectedRole, menu_id: permissions }),
        }
      );
      const roleData = await roleResponse.json();

      if (roleData.success) {
        toast.success("Role permissions updated successfully");
        setSelectedRole("");
        setSelectedUser("");
        setCheckedItems({});
      } else {
        toast.error(roleData.error);
      }
    }

    // Call User-wise API if a user is selected
    if (selectedUser) {
      const userResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/useraccess/userwise`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userid: selectedUser, menu_id: permissions }),
        }
      );
      const userData = await userResponse.json();

      if (userData.success) {
        toast.success("User permissions updated successfully");
        setSelectedRole("");
        setSelectedUser("");
        setCheckedItems({});
      } else {
        toast.error(userData.error);
      }
    }
  };

  // Refresh dropdown
  const handleCancel = () => {
    setSelectedRole("");
    setSelectedUser("");
    setCheckedItems({});
  };

  return (
    <Box sx={{ display: "flex" }}>
     
      <Main >
        <Typography sx={{ fontWeight: "bold" }}>
          User Access Permission
        </Typography>
        <Box
          variant="outlined"
          sx={{ width: 1070, border: 2, borderRadius: 4 }}
        >
          <Box sx={{ display: "flex", mb: 2 }}>
            {/* Dropdown for Roles */}
            <FormControl sx={{ minWidth: 250, margin: 5 }}>
              <InputLabel>Role</InputLabel>
              <Select
                value={selectedRole}
                onChange={handleRoleChange}
                label="Role"
              >
                {RoleItems.map((role) => (
                  <MenuItem key={role.roleid} value={role.roleid}>
                    {role.role}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Dropdown for Users */}
            <FormControl sx={{ minWidth: 250, margin: 5 }}>
              <InputLabel>User</InputLabel>
              <Select
                value={selectedUser}
                onChange={handleUserChange}
                label="User"
              >
                {UserItems.map((user) => (
                  <MenuItem key={user.user_id} value={user.user_id}>
                    {user.username}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Box
              sx={{
                align: "right",
                display: "flex",
                justifyContent: "flex-end",
                margin: 6,
              }}
            >
              {/* Update Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpdate}
                sx={{ height: 40 }}
              >
                Update
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                sx={{ height: 40, ml: 2 }}
              >
                Cancel
              </Button>
            </Box>
          </Box>

          {/* Menu Items with Checkboxes in Grid Layout */}
          <Box
            variant="outlined"
            sx={{
              maxWidth: "100%",
              border: 1,
              borderRadius: 4,
              margin: 2,
              padding: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid container spacing={1}>
              {menuItems.map((menuItem) => (
                <Grid item xs={3} key={menuItem.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkedItems[menuItem.id] || false}
                        onChange={() => handleCheckboxChange(menuItem)}
                      />
                    }
                    label={menuItem.menu_name}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Main>
    </Box>
  );
}
