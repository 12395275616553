import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from "@mui/material";
import { toast } from "react-toastify";
import { ThemeProvider } from '@mui/material/styles';
import theme from '../component/theme';
import { useNavigate } from 'react-router-dom';
import * as XLSX from "xlsx";
import { FileDownload } from "@mui/icons-material";
import Icon from '@mdi/react';
import { mdiMicrosoftExcel } from '@mdi/js';

const drawerWidth = 240;

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
 
  }),
}));
 
export default function View_Institutes() {
  const [searchText, setSearchText] = useState('');
  const [rows, setRows] = useState([]);
  const [allRows, setAllRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('institutes_id');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/viewinstitutes`);
        const data = await response.json();
        if (data.success && Array.isArray(data.success)) {
          setRows(data.success);
          setAllRows(data.success);
        } else {
          toast.error('Unexpected API response');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching data');
      }
    };
    fetchData();
  }, []);

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(allRows); 
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Institute"); 
    XLSX.writeFile(workbook, "Institute.xlsx"); 
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);

    const filteredRows = allRows.filter((row) => {
      return (
        (row.institutes_id && row.institutes_id.toString().toLowerCase().includes(value.toLowerCase())) ||
        (row.name && row.name.toLowerCase().includes(value.toLowerCase())) ||
        (row.email && row.email.toLowerCase().includes(value.toLowerCase()))
      );
    });
    setRows(filteredRows);
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
    
    const sortedRows = [...rows].sort((a, b) => {
      if (a[column] < b[column]) return isAsc ? -1 : 1;
      if (a[column] > b[column]) return isAsc ? 1 : -1;
      return 0;
    });
    setRows(sortedRows);
  };

  const displayedRows = rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Main>
          
              <Typography sx={{ fontWeight: 'bold', color: 'white',mb:-5 }}>
                Manage Your Institutes 
              </Typography>
           
            <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
              <Button type="submit" variant="contained" color="primary" onClick={() => navigate('/Create_Institutes')}>
                +CREATE NEW
              </Button>
            </Box>
          

          <Box sx={{ border: 2, width: "auto", borderRadius: 4 }}>
           <Box sx={{display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: 2}}>
           <Button
                  onClick={handleDownloadExcel}
                startIcon={<Icon path={mdiMicrosoftExcel} size={1} color="green" />}
                
              >
                <FileDownload />
              </Button>
           
            <TextField
              size="small"
              label="Search Institute"
              variant="outlined"
              value={searchText}
              onChange={handleSearch}
              
            /></Box>

            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{fontSize:20, fontWeight: 'bold', width: '25%' }}>
                      <span onClick={() => handleSort('institutes_id')} style={{ cursor: 'pointer' }}>
                        ID {sortColumn === 'institutes_id' && (sortDirection === 'asc' ? "▲" : "▼")}
                      </span>
                    </TableCell>
                    <TableCell sx={{ fontSize:20, fontWeight: 'bold', width: '25%' }}>
                      <span onClick={() => handleSort('name')} style={{ cursor: 'pointer' }}>
                        Institutes Name {sortColumn === 'name' && (sortDirection === 'asc' ? "▲" : "▼")}
                      </span>
                    </TableCell>
                    <TableCell sx={{ fontSize:20, fontWeight: 'bold', width: '25%' }}>
                      <span onClick={() => handleSort('email')} style={{ cursor: 'pointer' }}>
                        Email {sortColumn === 'email' && (sortDirection === 'asc' ? "▲" : "▼")}
                      </span>
                    </TableCell>
                    <TableCell sx={{ fontSize:20, fontWeight: 'bold', width: '25%' }}>
                      <span onClick={() => handleSort('contact_no')} style={{ cursor: 'pointer' }}>
                        Contact {sortColumn === 'contact_no' && (sortDirection === 'asc' ? "▲" : "▼")}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedRows.map((row) => (
                    <TableRow key={row.institutes_id}>
                      <TableCell>{row.institutes_id}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.contact_no}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              sx={{ marginBottom: 0 }}
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Main>
      </Box>
    </ThemeProvider>
  );
}
