import React, { useState, useCallback, useMemo } from 'react';
import { Box, TextField, Button, Typography, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import loginImage from '../assets/login.jpg'; 
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from "js-cookie";

// Debounce function to reduce frequent calls to input handlers
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [Username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');  // State for OTP input
  const [isSendingOtp , setisSendingOtp ] = useState(false);
  const [isVerifyingOtp , setisVerifyingOtp ] = useState(false); 
  const [isOtpSent, setIsOtpSent] = useState(false);  
  const navigate = useNavigate();

  // Toggle password visibility
  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  // Debounced input handlers to avoid rapid state updates
  const debouncedUsernameChange = useMemo(
    () => debounce((value) => setUsername(value), 300), // Debounce for 300ms
    []
  );

  const handleUsernameChange = (e) => {
    debouncedUsernameChange(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Handle OTP input change
  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Username) {
      toast.error('Please fill Email');
      setUsername('')
      return;
    }

    const user = { Username };

    try {
      setisSendingOtp(true);

      // First, attempt to send OTP
      const otpResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otpservice/send-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user), 
      });

      const otpData = await otpResponse.json();
      
      if (!otpResponse.ok) {
        toast.error(otpData.error || 'Failed to send OTP');
        setUsername('');
        setPassword('');
        return;
      }

      toast.success('OTP sent successfully!');
      setIsOtpSent(true);  // OTP sent successfully, show OTP input field

    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setisSendingOtp(false);
    }
  };

  // Handle OTP verification
  const handleVerifyOtp = async (e) => {
    e.preventDefault();

    if (!otp) {
      toast.error('Please enter the OTP.');
      return;
    }

    const user = { Username, otp };

    try {
      setisVerifyingOtp(true);

      const verifyOtpResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/otpservice/verify-otp`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const verifyOtpData = await verifyOtpResponse.json();

     
      

      if (!verifyOtpResponse.ok) {
        toast.error(verifyOtpData.error || 'OTP verification failed');
        setOtp('');
        return;
      }else{
        toast.success(verifyOtpData.message);
        setOtp('');
      }
      
    } catch (error) {
      toast.error('Something went wrong. Please try again.');
    } finally {
      setisVerifyingOtp(false);
    }
  };

const handlelogin = async (e) =>{
     e.preventDefault();

     
    if (!Username || !password) {
      toast.error('Please fill out all fields.');
      return;
    }

try {

      // Proceed to login after OTP is verified
      const loginResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ Username, password }),
      });

      const loginData = await loginResponse.json();

      if (!loginResponse.ok) {
        toast.error(loginData.error || 'Login failed');
        setUsername('');
        setPassword('');
        return;
      }

      // On successful login
      Cookies.set('login_jwtToken', loginData.logintoken);
      navigate('/Home');
      toast.success('User Login Successfully');
  
} catch (error) {
  toast.error('Something went wrong. Please try again.');
}


}



  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="black"
    >
      <Box
        display="flex"
        flexDirection="row"
        p={4}
        borderColor="white"
        borderRadius={2}
        border={4} 
        bgcolor="black"
        boxShadow="0 8px 32px rgba(31, 38, 135, 0.37)"
        sx={{
          backdropFilter: 'blur(16px)',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          border: '1.5px solid rgba(255, 255, 255, 0.18)',
        }}
      >
        {/* Image Section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pr={4}
        >
          <img src={loginImage} alt="Login" width={300} />
        </Box>

     
        <Box display="flex" flexDirection="column" justifyContent="center" gap={2} width={300}  
        sx={{ fontWeight: 'bold' }}>
          <Typography variant="h5" textAlign="center" color="white">
           LOGIN DASHBOARD
          </Typography>

       
          <form>
        
            <TextField
              label="Username"
              margin="normal"
              fullWidth
              onChange={handleUsernameChange}
              autoComplete="username"
              disabled={isSendingOtp} 
            />

            {/* Password Input */}
            <TextField
              label="Password"
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                      disabled={isSendingOtp} // Disable while loading
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
              disabled={isSendingOtp} 
            />
            <Typography align="right" color="white" marginY={1}>
              <Link to="/Forgot_password" style={{ color: 'white' }}>Forgot Password?</Link>
            </Typography>

            {/* Submit Button */}
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary" 
              
              type="submit"
              disabled={isSendingOtp} 
            > 
              {isSendingOtp ? 'Sending OTP...' : 'Send OTP'}
            </Button>
          </form>

          {/* OTP Verification Section */}
          {isOtpSent && (
            <form >
              <TextField
                label="Enter OTP"
                margin="normal"
                fullWidth
                value={otp}
                onChange={handleOtpChange}
                disabled={isVerifyingOtp}
              />
              <Button
                onClick={handleVerifyOtp}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                disabled={isVerifyingOtp}
              >
                {isVerifyingOtp ? 'Verifying OTP...' : 'Verify OTP'}
              </Button>

              <Button
                onClick={handlelogin}
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
               
              >
                {isVerifyingOtp ? 'Sign Ining...' : 'Sign'}
              </Button>

            </form>
          )}
        </Box>
      </Box>
    </Box>
  );
}
