import React, { useState, useCallback, useMemo } from 'react';
import { Box, TextField, Button, Typography, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import loginImage from '../assets/login.jpg'; 
import { toast } from 'react-toastify';
import { useNavigate,Link } from 'react-router-dom';


// Debounce function to reduce frequent calls to input handlers
const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), delay);
  };
};

export default function Forgot_password() {
  const [showPassword, setShowPassword] = useState(false);
  const [Username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Toggle password visibility
  const handleClickShowPassword = useCallback(() => {
    setShowPassword((prev) => !prev);
  }, []);

  // Debounced input handlers to avoid rapid state updates
  const debouncedUsernameChange = useMemo(
    () => debounce((value) => setUsername(value), 300), // Debounce for 300ms
    []
  );

  const handleUsernameChange = (e) => {
    debouncedUsernameChange(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Username || !password) {
      toast.error('Please fill out all fields.');
      return;
    }

    const user = { Username, password };

    try {
      setIsLoading(true);
      const apiresponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/resetpassword`, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      const temp = await apiresponse.json();   

      if (!apiresponse.ok) {
        toast.error(temp.error || 'An unknown error occurred');
        setPassword(''); // Clear password on failure
        setUsername('');
        return;
      }

      // On success
      toast.success('Password Update Successfully');
      navigate('/');
    } catch (error) {
      console.error('Error:', error);
      toast.error('Something went wrong. Please try again.');
    } finally {
      setIsLoading(false); // Ensure loading state is reset
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="black"
    >
      <Box
        display="flex"
        flexDirection="row"
        p={4}
        borderColor="white"
        borderRadius={2}
        border={4} 
        bgcolor="black"
        boxShadow="0 8px 32px rgba(31, 38, 135, 0.37)"
        sx={{
          backdropFilter: 'blur(16px)',
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          border: '1.5px solid rgba(255, 255, 255, 0.18)',
        }}
      >


        {/* Image Section */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pr={4}
        >
          <img src={loginImage} alt="Login" width={300} />
        </Box>

        {/* Form Section */}
        <Box display="flex" flexDirection="column" justifyContent="center" gap={2} width={300}>
          <Typography variant="h5" textAlign="center" color="white">
          Forgot Password
          </Typography>

          {/* Wrap form inputs in a form element */}
          <form onSubmit={handleSubmit}>
            {/* Username Input */}
            <TextField
              label="Username"
              margin="normal"
              fullWidth
              onChange={handleUsernameChange}
              autoComplete="username"
              disabled={isLoading} // Disable while loading
            />

            {/* Password Input */}
            <TextField
              label="New Password"
              type={showPassword ? 'text' : 'password'}
              margin="normal"
              fullWidth
              value={password}
              onChange={handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      edge="end"
                      aria-label="toggle password visibility"
                      disabled={isLoading} // Disable while loading
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="current-password"
              disabled={isLoading} // Disable while loading
            />

<Typography align="right" color="white" marginY={1}>
              <Link to="/" style={{ color: 'white' }}>Backto login?</Link>
            </Typography>

            {/* Submit Button */}
            <Button
              variant="contained"
              color="primary" 
              fullWidth
              type="submit"
              disabled={isLoading } // Disable if loading or fields are empty
            > 
              {isLoading ? 'Updating...' : 'Change Password'}
            </Button>
          </form>
        </Box>
      </Box>
    </Box>
  );
}
