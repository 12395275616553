import React, { useState, useEffect } from 'react';
import { ThemeProvider, CssBaseline, Box, AppBar, Toolbar, IconButton, Drawer, List, Divider, ListItem, ListItemButton, ListItemText, Menu, MenuItem, ListItemIcon } from '@mui/material';
import darkTheme from './theme';
import { ChevronLeft, Menu as MenuIcon } from '@mui/icons-material';
import * as Icons from '@mui/icons-material';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp';
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: open ? `${drawerWidth}px` : 0,
    width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
  })
);

export default function Sidenav() {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuItems, setMenuItems] = useState([]);
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  useEffect(() => {
    const token = Cookies.get('login_jwtToken');

    fetch(`${process.env.REACT_APP_BACKEND_URL}/sidenavapi`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `Bearer ${token}`,
      },
    })
      .then(response => {
        if (response.status === 401) {
          Cookies.remove('login_jwtToken');
          toast.info('Your session has expired. Please login again.');
          navigate('/');
          return Promise.reject('Token expired');
        }
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        if (data.message === "Token Expired") {
          Cookies.remove('login_jwtToken');
          toast.info('Your Session Expired. Please Login Again.');
          navigate('/');
        } else {
          setMenuItems(data);
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, [navigate]);

  const handleNavigate = (route) => {
    navigate(route);
    if (route === '/logout') {
      navigate('/');
      toast.success("Logout Successful");
      Cookies.remove("login_jwtToken");
    }
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (route) => {
    handleProfileMenuClose();
    handleNavigate(route);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box sx={{ display: 'flex' }}>
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            transition: 'width 0.3s ease, margin-left 0.3s ease',
            width: open ? `calc(100% - ${drawerWidth}px)` : '100%',
            ml: open ? `${drawerWidth}px` : 0,
            backgroundColor: 'black',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="profile"
              onClick={handleProfileMenuOpen}
              sx={{ marginLeft: 'auto', width: 50 }}
            >
              <ExitToAppSharpIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleProfileMenuClose}
            >
              <MenuItem onClick={() => handleMenuItemClick('/logout')}>
                <PowerSettingsNewSharpIcon />Logout
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>

        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              transition: 'width 0.3s ease',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List>
            {menuItems.map((item, index) => (
              <ListItem key={index} disablePadding>
                <ListItemButton onClick={() => handleNavigate(item.route)}>
                  <ListItemIcon>
                    {Icons[item.icon] ? React.createElement(Icons[item.icon]) : <MenuIcon />}
                  </ListItemIcon>
                  <ListItemText primary={item.menu_name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>

        <Main open={open}>
          <Toolbar />
          <Outlet />
        </Main>
      </Box>
    </ThemeProvider>
  );
}
