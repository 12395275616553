import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import darkTheme from './theme';
import { ThemeProvider, CssBaseline } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'; 
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PeopleIcon from '@mui/icons-material/People';
import { BarChart } from '@mui/x-charts/BarChart';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { toast } from 'react-toastify';
import Cookies from "js-cookie";
import {jwtDecode }  from "jwt-decode"; 
import { useNavigate } from 'react-router-dom';

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const drawerWidth=240;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    flexGrow: 1,
  
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
      
    }),
}));


export default function Home() {
    const navigate = useNavigate();
    const [cardDetails, setCardDetails] = useState([
        { header: 'Loading...', value: 0 },
        { header: 'Loading...', value: 0 },
        { header: 'Loading...', value: 0 },
        { header: 'Loading...', value: 0 },
    ]);
//     const [dashboardData, setDashboardData] = useState({
//         allowedBatches: 0,
//         enrolledBatches: 0,
//         remainingBatches: 0,
//         enrolledStudents: 0
//     });
 
    
    useEffect(() => {

              
         const token = Cookies.get("login_jwtToken");
        //  if (token) {
        //      try {
                 
        //          const decodedToken = jwtDecode(token);
        //          console.log("Decoded Token:", decodedToken); 
                 
                
        //      } catch (error) {
        //          console.error("Error decoding token:", error);
        //      }
        //  }

        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dashboard`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                       'authorization': `Bearer ${token}`,
                    },
                  });
                const data = await response.json();
                

                if (data.message === "Token Expired") {
                    Cookies.remove('login_jwtToken');
                    toast.info('Your Session Expired. Please Login Again.');
                    navigate('/');
                  }else{
                   // setDashboardData(data);
                    if (data.message.type === "admin") {
                       
                        setCardDetails([
                          {
                            key: "totalinstitues",
                            header: "Total Institutes",
                            value: data.message.totalinstitues,
                          },
                          {
                            key: "totalstudent",
                            header: "Total Student",
                            value: data.message.totalstudent,
                          },
                          {
                            key: "totalallowbatch",
                            header: "Total Allow Batches",
                            value: data.message.totalallowbatch,
                          },
                          {
                            key: "totalenrollbatch",
                            header: "Total Enroll Baches",
                            value: data.message.totalenrollbatch,
                          },
                        ]);
                      } else {
                       

                        setCardDetails([
                            {
                              key: "allowedBatches",
                              header: "Allowed Batches",
                              value: data.message.allowedBatches,
                            },
                            {
                              key: "enrolledBatches",
                              header: "Enrolled Batches",
                              value: data.message.enrolledBatches,
                            },
                            {
                              key: "remainingBatches",
                              header: "Remaining Batches",
                              value: data.message.remainingBatches,
                            },
                            {
                              key: "enrolledstudent",
                              header: "Total Enroll Students",
                              value: data.message.enrolledstudent,
                            },
                          ]);
                      }

                  }
               

                if (data.error) {
                    toast.error('Internal server error');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                toast.error('Error fetching data');
            }
        };

        fetchData(); 

   
     }, [navigate]);

    const doughnutData = {
        labels: cardDetails.map((detail) => detail.header),
        datasets: [
            {
                
                data: cardDetails.map((detail) => detail.value),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
                color: ['white'],
            },
        ],
    };

    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '50%', 
    };

    return (
        <ThemeProvider theme={darkTheme}>
      <CssBaseline /> 
        <Box sx={{ display: 'flex' }}>
            
            <Main  sx={{ background: 'black' }}>
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <Card variant="outlined" sx={{ height: 125, width: 250, padding: 4, border: 2, borderRadius: 4 }}>
                        <CardContent sx={{ display: 'flex', marginTop: -5, marginLeft: -5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, backgroundColor: '#1a73e8', borderRadius: '50%' }}>
                                <PeopleIcon sx={{ fontSize: 30, color: '#fff' }} />
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Box sx={{ marginTop: -11, marginLeft: 4 }}>
                                <Typography component="div" sx={{ fontWeight: 'bold', color: 'white' }}>
                                {cardDetails[0].header}</Typography>
                                <CardContent>
                                    <Box>
                                        <Typography sx={{ fontSize: 40, marginTop: -2, fontWeight: 'bold', color: '#1173e8' }}>
                            
                                        {cardDetails[0].value}  </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </CardContent>
                    </Card>
                <Card variant="outlined" sx={{ height: 125, width: 250, padding: 4, border: 2, borderRadius: 4 }}>
                        <CardContent sx={{ display: 'flex', marginTop: -5, marginLeft: -5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, backgroundColor: 'orange', borderRadius: '50%' }}>
                                <PeopleIcon sx={{ fontSize: 30, color: '#fff' }} />
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Box sx={{ marginTop: -11, marginLeft: 4 }}>
                                <Typography component="div" sx={{ fontWeight: 'bold', color: 'white' }}>{cardDetails[1].header}</Typography>
                                <CardContent>
                                    <Box>
                                        <Typography sx={{ fontSize: 40, marginTop: -2, fontWeight: 'bold', color: 'orange' }}>
                                        {cardDetails[1].value}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Card for Remaining Batches */}
                    <Card variant="outlined" sx={{ height: 125, width: 250, padding: 4, border: 2, borderRadius: 4 }}>
                        <CardContent sx={{ display: 'flex', marginTop: -5, marginLeft: -5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, backgroundColor: 'green', borderRadius: '50%' }}>
                                <PeopleIcon sx={{ fontSize: 30, color: '#fff' }} />
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Box sx={{ marginTop: -12, marginLeft: 4 }}>
                                <Typography component="div" sx={{ fontWeight: 'bold', color: 'white' }}>{cardDetails[2].header}</Typography>
                                <CardContent>
                                    <Box>
                                        <Typography sx={{ fontSize: 40, marginTop: -2, fontWeight: 'bold', color: 'green' }}>
                                        {cardDetails[2].value}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </CardContent>
                    </Card>

                    {/* Card for Total Enrolled Students */}
                    <Card variant="outlined" sx={{ height: 125, width: 250, padding: 4, border: 2, borderRadius: 4 }}>
                        <CardContent sx={{ display: 'flex', marginTop: -5, marginLeft: -5 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 60, height: 60, backgroundColor: '#a4ca1f', borderRadius: '50%' }}>
                                <PeopleIcon sx={{ fontSize: 30, color: '#fff' }} />
                            </Box>
                        </CardContent>
                        <CardContent>
                            <Box sx={{ marginTop: -12, marginLeft: 4 }}>
                                <Typography component="div" sx={{ fontWeight: 'bold', color: 'white' }}>{cardDetails[3].header}</Typography>
                                <CardContent>
                                    <Box>
                                        <Typography sx={{ fontSize: 40, marginTop: -2, fontWeight: 'bold', color: '#a4ca1f' }}>
                                        {cardDetails[3].value}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Box>
                        </CardContent>
                    </Card>
                </Box>

                <Box sx={{ display: 'flex', gap: 1 }}>
                    <Card variant="outlined" sx={{ height: 350, width: 580, padding: 4, border: 2, borderRadius: 4, marginTop: 3 }}>
                        {/* Bar Chart */}
                        <BarChart
                            sx={{ marginTop: 2, marginLeft: -5 }}
                            series={[{
                                
                                data: cardDetails.map((detail) => detail.value),
                                color: '#1a73e8',
                            }]}
                            xAxis={[{
                                data: cardDetails.map((detail) => detail.header),
                                scaleType: 'band',
                                tick: {
                                    style: {
                                        fill: 'white', 
                                    }
                                },
                            }]}
                            yAxis={[{
                                tick: {
                                    style: {
                                        fill: 'white', 
                                    }
                                },
                            }]}
                            height={290}
                            margin={{ top: 40, bottom: 30, left: 35, right: -10 }}
                            width={550}
                        />
                    </Card>

                    <Card variant="outlined" sx={{  height: 350, width: 480, padding: 4, border: 2, borderRadius: 4, marginTop: 3}}>
                        {/* Doughnut Chart */}
                        <Doughnut data={doughnutData} options={doughnutOptions} />
                    </Card>
                </Box>
            </Main>
        </Box>
        </ThemeProvider>
    );
}
