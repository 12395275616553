import React, { useState, useEffect } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import StudentList from "./StudentList";
import { toast } from "react-toastify";
import theme from '../component/theme';
import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import dayjs from 'dayjs';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { CaptureFinger } from  "../utils/fingerprintSDK.js"

const drawerWidth = 240;


const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    flexGrow: 1,
    
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
     
    }),
}));

const FingerprintBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 150,
    boxShadow: theme.shadows[3],
}));

export default function Student() {
    
    const { id,studentname, fathername,dob,contact,batchid,aadhaarno,fingerprintslink} = useParams();

    console.log("fingerprintslink",fingerprintslink)
 
    const [studentName, setStudentName] = useState(studentname || '');
    const [fathersName, setFathersName] = useState(fathername || '');
    const [phoneNumber, setPhoneNumber] = useState(contact || '');
    const [batch, setBatch] = useState([]); 
    const [getbatchid, setBatchid] = useState([]); 
    const [aadhaar, setAadhaar] = useState(aadhaarno || '');
    const [fingerprints, setFingerprints] = useState( Array(5).fill({ BitmapData: null, Quality: null }));
    const formattedStartDate = dob ? dayjs(dob) : null;
    const [date, setDate] = useState( formattedStartDate || null);
    const [selectedBatch, setSelectedBatch] = useState("");
    const [loading, setLoading] = useState(true);
  
 
   const handleCapture = async (index) => {
    try {
        const response = await CaptureFinger(1, 5000);
        if (response.httpStaus) {
            setFingerprints((prevFingerprints) => {
                const updatedFingerprints = [...prevFingerprints];
                updatedFingerprints[index] = response.data; 
                return updatedFingerprints;
            });
            toast.success(`Fingerprint ${index + 1} captured successfully!`);
        } else {
            console.error("Error capturing fingerprint:", response.err);
            toast.error("Error capturing fingerprint. Please try again.");
        }
    } catch (error) {
        console.error("Capture error:", error);
        toast.error("Unexpected error occurred during fingerprint capture.");
    }
};

    



    useEffect(() => {
        const fetchBatches = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/viewbatch`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${Cookies.get("login_jwtToken")}`,
                    },
                });
    
                const data = await response.json();
                if (data.success) {
                    setBatch(data.success); 

                    if (getbatchid) {
                        const matchingBatch = data.success.find(batch => batch.batch_id === parseInt(getbatchid, 10));
                        if (matchingBatch) {
                            setSelectedBatch(matchingBatch.batch_id); // Set selectedBatch
                        }
                    }
                    setLoading(false); 
                } else {
                    toast.error("Failed to fetch batch data");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching batch data");
                setLoading(false);
            }
        };
    
        fetchBatches();
    }, [getbatchid]);
    
    useEffect(() => {
        if (id) {
            // Update state when parameters change
            setStudentName(studentname);
            setFathersName(fathername);
            setPhoneNumber(contact);
            setBatchid(batchid);
            setAadhaar(aadhaarno);
            setDate(dob);
           
        }

    }, [id, studentname, fathername, dob, contact, batchid, aadhaarno]);

    const handlePhoneChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setPhoneNumber(value);
        }
    };

    const handleAadhaarChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 12) {
            setAadhaar(value);
        }
    };

    const handleInputs = (setter) => (event) => {
        setter(event.target.value);
       
        
    };

    //  file upload for fingerprints
    // const handleFingerprintUpload = (index) => (event) => {
    //     const file = event.target.files[0];
    //     if (file) {
    //         setFingerprints((prevFingerprints) => {
    //             const newFingerprints = [...prevFingerprints];
    //             newFingerprints[index] = file; // Store the file in the respective slot
    //             return newFingerprints;
    //         });
    //         toast.success(`Fingerprint ${index + 1} uploaded successfully`);
    //     }
    // };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = Cookies.get("login_jwtToken");
       
        if (phoneNumber.length !== 10) {
            toast.error("Phone number must be exactly 10 digits.");
            return;
        }
        if (aadhaar.length !== 12) {
            toast.error("Aadhaar number must be exactly 12 digits.");
            return;
        }

        
        const allFingerprintsCaptured = fingerprints.every(fingerprint => fingerprint.BitmapData !== null);
    if (!allFingerprintsCaptured) {
        toast.error("All fingerprints must be captured before submitting.");
        return;
    }


    
        let  formData  = {
            studentid : id || '',
            studentname : studentName,
            fathername : fathersName,
            dob : date ? date.format('YYYY-MM-DD') : '',
            contact: phoneNumber,
            batch : selectedBatch,
            aadhaarno : aadhaar,

        }
        fingerprints.forEach((fingerprint, index) => {
            if (fingerprint && fingerprint.BitmapData) {
                formData[`finger${index + 1}`]= fingerprint.BitmapData;
            }
        });

    


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/createstudent`, {
                method: 'POST',
                headers: {
                     'Authorization': `Bearer ${token}`,
                     'Content-Type': 'application/json',
                  },
                body: JSON.stringify(formData),
            
            }); 
           
        
            if (!response.ok) {
                throw new Error('Failed to create student');
            }

            const data = await response.json();

            if (data.error) {
                toast.error(data.error);
            } else {
                toast.success('Student created successfully');
                setStudentName('');
                setFathersName('');
                setPhoneNumber('');
                setBatch('');
                setAadhaar('');
                setDate(null);
                setFingerprints(Array(5).fill({ BitmapData: null, Quality: null }));
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message);
        }
    };




    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>        
                <Main>
                    <Typography sx={{ fontWeight: 'bold' }}>Manage Your Students</Typography>
                    <Box variant="outlined" sx={{ width: 1050, border: 2, borderRadius: 4 }}>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ '& > :not(style)': { m: 1, width: '320px' } }}
                            noValidate
                            autoComplete="off">
                            <TextField size="small" type="text" value={studentName} onChange={handleInputs(setStudentName)} label="Student Name" variant="outlined" />
                            <TextField size="small" type="text" value={fathersName} onChange={handleInputs(setFathersName)} label="Father's Name" variant="outlined" />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Date of Birth" value={date} onChange={setDate} renderInput={(params) => <TextField {...params} size="small" />} />
                            </LocalizationProvider>

                            <TextField size="small" type="text" value={phoneNumber} onChange={handlePhoneChange} label="Contact No." variant="outlined" inputProps={{ maxLength: 10, inputMode: "numeric" }} />
                        
                            <FormControl size="small">
                        <InputLabel id="batch-label">{loading ? "Loading..." : "Select Batch"}</InputLabel>
                        <Select
      labelId="batch-label"
      id="batch-select"
      value={selectedBatch || ""}
      onChange={(event) => setSelectedBatch(event.target.value)}
      label={loading ? "Loading..." : "Select Batch"}
      disabled={loading}
    >
      {loading ? (
        <MenuItem disabled>
          <em>Loading...</em>
        </MenuItem>
      ) : batch && batch.length > 0 ? (
        batch.map((batchs) => (
          <MenuItem key={batchs.batch_id} value={batchs.batch_id}>
            {batchs.batch_name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>
          <em>No Batches Available</em>
        </MenuItem>
      )}
    </Select>
</FormControl>
                            <TextField size="small" value={aadhaar} onChange={handleAadhaarChange} label="Aadhaar No." variant="outlined" inputProps={{ maxLength: 12, inputMode: "numeric" }} />

                            <Box sx={{ display: "flex", gap: 5 }}>
    {fingerprints.map((fingerprint, index) => (
        <FingerprintBox key={index}>
            <Typography gutterBottom>Finger {index + 1}</Typography>
            <img
                src={fingerprint ? `data:image/png;base64,${fingerprint.BitmapData}` : ''}
                alt=''
                style={{ width: '100%', height: '100%',filter: 'invert(100%) contrast(200%)', backgroundcolor: 'black' }}
                
            />
              {fingerprint.Quality !== null && (
                <Typography variant="caption" color="textSecondary">
                    Quality: {fingerprint.Quality }
                </Typography>
            )}
            <Button sx={{ m: 1 }} variant="contained" onClick={() => handleCapture(index)}>
                 Capture
            </Button>
        </FingerprintBox>
    ))}
</Box>

                            <Button variant="contained" type="submit">
                             {id ? 'Update' : 'Submit'}
                            </Button>
                             </Box>
                    </Box>
                    <Box variant="outlined" sx={{ mt: 2, width: 'auto', border: 2, borderRadius: 4 }}>
                        <StudentList />
                    </Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}
   